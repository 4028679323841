import React from "react";
import "./Portfolio.css";
import IMG1 from "../../assets/portfolio/portfolio001.png";
import IMG2 from "../../assets/portfolio/portfolio002.jpg";
import IMG3 from "../../assets/portfolio/portfolio003.jpg";
import IMG4 from "../../assets/portfolio/portfolio004.jpg";
import IMG5 from "../../assets/portfolio/portfolio005.jpg";
import IMG6 from "../../assets/portfolio/portfolio006.jpg";
import IMG7 from "../../assets/portfolio/portfolio007.jpg";
import IMG8 from "../../assets/portfolio/portfolio008.jpg";
import IMG9 from "../../assets/portfolio/portfolio009.jpg";
import IMG10 from "../../assets/portfolio/portfolio0010.jpg";
import IMG11 from "../../assets/portfolio/portfolio0011.jpg";
import "react-photo-view/dist/react-photo-view.css";
import { PhotoProvider, PhotoView } from "react-photo-view";

const PortfolioList = [
  {
    title: "iCard.lk",
    image: IMG1,
    display:
      "Stand out from the crowd with digital business cards. Not only are digital business cards better for the businesses, It’ s also better for your Network Smarter and will help you make a great first impression",
    view: "https://www.icard.lk",
  },
  {
    title: "Cleaninger",
    image: IMG2,
    display:
      "Cleaninger is India's most efficient and affordable premier listing service. When you choose Cleaninger, you get the best offers available in the market and negotiate your terms with the top service providers",
    view: "https://cleaninger.com",
  },
  {
    title: "Buonavista College",
    image: IMG3,
    display:
      "Buonavista College is a school located in Rumassala, Unawatuna, Galle in Southern Sri Lanka.",
    view: "#",
  },
  {
    title: "Event - CDUK",
    image: IMG4,
    display:
      "Mange your events with our Event Management System. You can create your own events, manage your events, and get reports on your events.",
    view: "https://event.cduk.lk",
  },
  {
    title: "CodeDevex",
    image: IMG5,
    display:
      "We help you define a winning digital strategy with the right combination of products, services, capabilities and technologies to ensure you reach your business goals in this digital world..",
    view: "https://www.codedevex.com",
  },
  {
    title: "ArcticTern Immigration Consulting",
    image: IMG6,
    display:
      "ArcticTern Immigration Consulting is an immigration consulting company registered in Ontario, Canada under Canada Business Corporations Act. We provide immigration advices and related..",
    view: "https://www.arcticternconsulting.ca/",
  },
  {
    title: "Glee J Real Estate",
    image: IMG7,
    display:
      "Glee J Ceylon Real Estate is a professional real estate firm for home search, selling, renting properties, and development in Sri Lanka.",
    view: "https://www.gleejceylon.com",
  },
  {
    title: "Campus Direct",
    image: IMG8,
    display:
      "Campus Direct is a leading Career Guidance, Counselling and Student Placement Services Center for Overseas Higher Education in Sir Lanka since 2008",
    view: "https://campusdirect.io",
  },
  {
    title: "Mars Solid Surfaces - Dubai",
    image: IMG9,
    display:
      "We specialize in custom fabrication and installation of solid surface. We are the leading solid surface fabricator in Dubai, UAE.",
    view: "https://www.marssurfaces.com",
  },
  {
    title: "Samara Care",
    image: IMG10,
    display:
      "Samara Care is a premium home care service offered to the elderly of Sri Lanka.",
    view: "https://www.samara.care",
  },
  {
    title: "Manu Ceylon Herbs",
    image: IMG11,
    display:
      "Manu Ceylon Herbs are a company specializing in the provision of Ayurvedic-related product and services in Sri Lanka.",
    view: "https://www.manuceylon.lk/",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {PortfolioList.map((item, index) => (
          <article className="portfolio_item" key={index}>
            <div className="portfolio_item-image">
              <PhotoProvider>
                <PhotoView  src={item.image}>
                  <img src={item.image} alt="" />
                </PhotoView>
              </PhotoProvider>
            </div>
            <h3>{item.title}</h3>
            <p>{item.display}</p>
            {/* <a href={item.view} target="_blank" rel="noopener noreferrer">
              View
            </a> */}
            {/* <div className="portfolio_item-cta">
              <a
                href={item.view}
                className="btn btn-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                View
              </a>
            </div> */}
          </article>
        ))}
      </div>
    </section>
  );
};

export default Portfolio;
