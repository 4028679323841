import React from "react";
import "./Experience.css";
import { BsPatchCheckFill } from "react-icons/bs";

const Experience = () => {
  const experienceFrontend = [
    {
      title: "React JS",
      level: "Experienced",
    },
    {
      title: "Next Js",
      level: "Experienced",
    },
    {
      title: "Node Js",
      level: "Experienced",
    },
    {
      title: "Vue Js",
      level: "Experienced",
    },
    {
      title: "JavaScript",
      level: "Experienced",
    },
    {
      title: "HTML5",
      level: "Experienced",
    },
    {
      title: "CSS3",
      level: "Experienced",
    },
    {
      title: "SASS",
      level: "intermediate",
    },
    {
      title: "WordPress",
      level: "Experienced",
    },
    {
      title: "BigCommerce",
      level: "intermediate",
    },
  ];

  // Basic, Experienced, intermediate

  const experienceBackend = [
    {
      title: "PHP",
      level: "Experienced",
    },
    {
      title: "Laravel",
      level: "Experienced",
    },
    {
      title: "GraphQL",
      level: "intermediate",
    },
    {
      title: "RabbitMQ",
      level: "intermediate",
    },
    {
      title: "Mysql",
      level: "intermediate",
    },
    {
      title: "CakePHP",
      level: "Basic",
    },
  ];

  return (
    <section id="experience">
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Frontend Development</h3>
          <div className="experience__content">
            {experienceFrontend.map((item, index) => (
              <article className="experience__details" key={index}>
                <BsPatchCheckFill className="experience__details-icon" />
                <div>
                  <h5>{item.title}</h5>
                  <small className="text-light">{item.level}</small>
                </div>
              </article>
            ))}
          </div>
        </div>
        <div className="experience__backend">
          <h3>Backend Development</h3>
          <div className="experience__content">
            {experienceBackend.map((item, index) => (
              <article className="experience__details" key={index}>
                <BsPatchCheckFill className="experience__details-icon" />
                <div>
                  <h5>{item.title}</h5>
                  <small className="text-light">{item.level}</small>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
