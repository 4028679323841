import React from "react";
import "./About.css";
import ME from "../../assets/ProfileNew.png";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="AboutImage" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about_icon" />
              <h5>Experience</h5>
              <small>5+ Years Working </small>
            </article>

            <article className="about__card">
              <FiUsers className="about_icon" />
              <h5>Clients</h5>
              <small>20+ Worldwide</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className="about_icon" />
              <h5>Projects</h5>
              <small>30+ Completed</small>
            </article>
          </div>

          <p>
            I’m a Ful-stack Developer located in Sri Lanka. I have a serious
            passion for UI effects, animations and creating intuitive, dynamic
            user experiences.
          </p>

          <p>
            Well-organised person, problem solver, independent employee with
            high attention to detail. Fan of AWS, outdoor activities, TV series
            and English literature.
          </p>

          <p>
            Interested in the entire frontend spectrum and working on ambitious
            projects with positive people.
          </p>

          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
