import React from "react";
import { BsLinkedin } from "react-icons/bs";
import { FaGithub } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { BsYoutube } from "react-icons/bs";
const HeaderSocial = () => {
  return (
    <div className="header_socials">
      <a
        href="https://www.youtube.com/channel/UCKWk4-e363t_0GdK67d1PdQ"
        rel="noreferrer"
        target="_blank"
      >
        <BsYoutube />
      </a>
      <a
        href="https://www.linkedin.com/in/bhanuka-krish-614550122/"
        rel="noreferrer"
        target="_blank"
      >
        <BsLinkedin />
      </a>
      <a
        href="https://github.com/BhanukaKrish"
        rel="noreferrer"
        target="_blank"
      >
        <FaGithub />
      </a>
      <a
        href="https://www.facebook.com/bhanuka.krish"
        rel="noreferrer"
        target="_blank"
      >
        <FaFacebookSquare />
      </a>
      <a
        href="https://www.instagram.com/bhanukakrish/?hl=en"
        rel="noreferrer"
        target="_blank"
      >
        <AiFillInstagram />
      </a>
    </div>
  );
};

export default HeaderSocial;
